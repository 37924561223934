import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { NOTIFICATIONS_URI } from '../../config';
import { NotificationQueryParams, PortalNotification } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly #httpClient = inject(HttpClient);
  readonly uri = inject(NOTIFICATIONS_URI);

  getNotifications(query: Query<NotificationQueryParams>): Observable<IQueryResponse<PortalNotification>> {
    return this.#httpClient.get<IQueryResponse<PortalNotification>>(this.uri, { params: query.toQueryParams() });
  }
}
