import { NotificationFilters, NotificationsRequestMetadata } from '../../core';

export class NotificationGetMany {
  static readonly type = '[Notification] GetMany';
}

export class NotificationInitFilters {
  static readonly type = '[Notification] InitFilters';
}

export class NotificationSetFilter {
  static readonly type = '[Notification] SetFilter';
  constructor(readonly payload?: NotificationFilters) {}
}

export class NotificationPatchFilter {
  static readonly type = '[Notification] PatchFilter';
  constructor(readonly payload?: Partial<NotificationFilters>) {}
}

export class NotificationResetFilter {
  static readonly type = '[Notification] ResetFilter';

  constructor(readonly payload?: Partial<NotificationFilters>) {}
}

export class NotificationPatchRequestMeta {
  static readonly type = '[Notification] PatchRequestMeta';
  constructor(readonly payload: Partial<NotificationsRequestMetadata>) {}
}

export class NotificationSetReportsGenerationListener {
  static readonly type = '[Notification] SetReportsGenerationListener';

  constructor(readonly userId: string) {}
}

export class NotificationDisposeReportsGenerationListener {
  static readonly type = '[Notification] DisposeReportsGenerationListener';
}
