import { StateToken } from '@ngxs/store';

import { BaseRequestMetadata, BaseResponseMetadata, EntityListStateModel } from '@supy/common';

import { PortalNotification } from '../entities';

export const NOTIFICATION_STATE_TOKEN = new StateToken<NotificationStateModel>('notifications');

export interface NotificationStateModel extends EntityListStateModel<PortalNotification> {
  filters: NotificationFilters;
  requestMetadata: NotificationsRequestMetadata;
  responseMetadata: BaseResponseMetadata;
  exportLoading: boolean;
}

export interface NotificationFilters {
  status?: string;
  branches?: string[];
  date?: Date | number | null;
  suppliers?: string[];
}

export interface NotificationsRequestMetadata extends BaseRequestMetadata {
  readonly channelIds: string[];
  readonly retailerId: string | null;
}
