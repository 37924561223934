export class PortalNotification {
  readonly id: string;
  readonly module: string;
  readonly event: string;
  readonly detail: string;
  readonly status: string;
  readonly retailerId: string;
  readonly action?: string;
  readonly time: Date;

  constructor(entity: Partial<PortalNotification>) {
    Object.assign(this, entity);
  }
}

export class NotificationQueryParams extends PortalNotification {
  readonly 'supplier.id': string;
  readonly 'retailer.id': string;
  readonly 'location.id': string;
  readonly 'document.number': string;
  readonly 'document.invoiceDate': string;
  readonly 'document.documentDate': string;
  readonly 'metadata.isLocked': boolean;
}
