import { Injectable } from '@angular/core';

import { LocalStorageService } from '@supy/common';

import { NOTIFICATION_STATE_TOKEN, NotificationStateModel } from '../../core';

const NOTIFICATIONS_STORAGE_KEY = NOTIFICATION_STATE_TOKEN.getName();

@Injectable({
  providedIn: 'root',
})
export class NotificationsStorageService extends LocalStorageService {
  setNotifications(notificationsState: NotificationStateModel): void {
    this.setItem(NOTIFICATIONS_STORAGE_KEY, notificationsState);
  }

  resetNotifications() {
    this.removeItem(NOTIFICATIONS_STORAGE_KEY);
  }
}
